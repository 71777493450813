import { LoadingStateEnum } from '@dmc-ng/data-access';
import { Action, createReducer, on } from '@ngrx/store';

import { profileActions } from './profile.actions';
import { CountModel } from '../models/count.model';
import { ImportModel } from '../models/import.model';

export const PROFILE_FEATURE_KEY = 'profile';

export interface ProfileState {
  countsDetails?: CountModel[];
  error?: unknown;
  loading: LoadingStateEnum;
  loadingTargetReach: LoadingStateEnum;
  countingDatasets?: ProfileDatasetsState;
  import: ProfileImportState;
}

export interface ProfileImportState {
  importIdUploaded?: string;
  currentImport?: ImportModel;
  error?: unknown;
  loading: LoadingStateEnum;
}

export interface ProfileDatasetsState {
  loading: LoadingStateEnum;
  datasetId?: string;
  datasetName?: string;
  error?: unknown;
}

export const initialProfileState: ProfileState = {
  loading: LoadingStateEnum.Ready,
  loadingTargetReach: LoadingStateEnum.Ready,
  import: {
    loading: LoadingStateEnum.Ready,
  },
};

const reducer = createReducer(
  initialProfileState,
  on(
    profileActions.getTargetReach,
    profileActions.uploadBlacklistDatabaseWithCampaignIds,
    profileActions.importProfile,
    (state) => ({ ...state, loading: LoadingStateEnum.Loading }),
  ),
  on(
    profileActions.targetReachFailedToBeRetrieved,
    profileActions.blacklistDatabaseWithCampaignIdsFailedToBeValidated,
    profileActions.importProfileFailedToBeUpload,
    (state, { error }) => ({ ...state, error, loading: LoadingStateEnum.Done }),
  ),
  on(
    profileActions.targetReachRetrievedSuccessfully,
    profileActions.blacklistDatabaseWithCampaignIdsSentSuccessfully,
    profileActions.importProfileSuccessfully,
    (state) => ({ ...state, loading: LoadingStateEnum.Done }),
  ),

  on(profileActions.getTargetReach, (state) => ({
    ...state,
    loadingTargetReach: LoadingStateEnum.Loading,
  })),
  on(profileActions.targetReachRetrievedSuccessfully, (state, { counts }) => ({
    ...state,
    countsDetails: counts,
    loadingTargetReach: LoadingStateEnum.Done,
  })),
  on(profileActions.targetReachFailedToBeRetrieved, (state, { error }) => ({
    ...state,
    error,
    loadingTargetReach: LoadingStateEnum.Done,
  })),
  on(profileActions.uploadBlacklistDatabaseWithCampaignIds, (state) => ({
    ...state,
    countingDatasets: {
      ...state.countingDatasets,
      datasetName: undefined,
      datasetId: undefined,
      error: undefined,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    profileActions.blacklistDatabaseWithCampaignIdsSentSuccessfully,
    (state, { datasetId, datasetName }) => ({
      ...state,
      countingDatasets: {
        ...state.countingDatasets,
        error: undefined,
        datasetId,
        datasetName,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    profileActions.blacklistDatabaseWithCampaignIdsFailedToBeValidated,
    (state, { error }) => ({
      ...state,
      countingDatasets: {
        ...state.countingDatasets,
        error,
        datasetId: undefined,
        datasetName: undefined,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(profileActions.resetDatasetStore, (state) => ({
    ...state,
    countingDatasets: {
      ...state.countingDatasets,
      error: undefined,
      datasetId: undefined,
      datasetName: undefined,
      loading: LoadingStateEnum.Ready,
    },
  })),
  on(profileActions.importProfile, (state) => ({
    ...state,
    import: {
      ...state.import,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    profileActions.importProfileSuccessfully,
    (state, { importProfileId }) => ({
      ...state,
      import: {
        ...state.import,
        importIdUploaded: importProfileId,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(profileActions.importProfileFailedToBeUpload, (state, { error }) => ({
    ...state,
    import: {
      ...state.import,
      importIdUploaded: undefined,
      loading: LoadingStateEnum.Done,
      error,
    },
  })),
);

export function profileReducer(
  state: ProfileState | undefined,
  action: Action,
): ProfileState {
  return reducer(state, action);
}
