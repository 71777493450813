import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PROFILE_FEATURE_KEY, ProfileState } from './profile.reducers';

export const selectProfileState =
  createFeatureSelector<ProfileState>(PROFILE_FEATURE_KEY);

export const selectProfileLoading = createSelector(
  selectProfileState,
  (state) => state.loading,
);

export const selectTargetReachLoading = createSelector(
  selectProfileState,
  (state) => state.loadingTargetReach,
);

export const selectProfileError = createSelector(
  selectProfileState,
  (state) => state.error,
);

export const selectProfileBlacklistDatasetId = createSelector(
  selectProfileState,
  (state) => state.countingDatasets?.datasetId,
);

export const selectProfileBlacklistDatasetName = createSelector(
  selectProfileState,
  (state) => state.countingDatasets?.datasetName,
);

export const selectBlacklistDatasetIdError = createSelector(
  selectProfileState,
  (state) => state.countingDatasets?.error,
);

export const selectBlacklistDatasetIdLoading = createSelector(
  selectProfileState,
  (state) => state.countingDatasets?.loading,
);

export const selectBlacklistDataset = createSelector(
  selectProfileState,
  (state) => state.countingDatasets,
);

export const selectCountsDetails = createSelector(
  selectProfileState,
  (state) => state.countsDetails,
);

export const selectImportIdUploaded = createSelector(
  selectProfileState,
  (state) => state.import.importIdUploaded,
);

export const selectCurrentImportProfile = createSelector(
  selectProfileState,
  (state) => state.import.currentImport,
);
