import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { Observable } from 'rxjs';

import { CountDetailsModel } from './models/count.model';
import { DatasetModel } from './models/dataset.model';
import { DimensionsEnum } from './models/enum/dimensions.enum';
import { CountFilterModel } from './models/filter-operator.model';
import { ImportItem, ImportModel, ImportObject } from './models/import.model';

@Injectable()
export class ProfileService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getCount(
    organizationId: string,
    accountId: string,
    filters: CountFilterModel,
    withLabel: boolean,
    dimensions?: DimensionsEnum[],
  ): Observable<APIResponse<CountDetailsModel>> {
    let params = new HttpParams();
    if (dimensions) {
      params = params.append('dimensions', dimensions.join(','));
    }
    params = params.append('labels', withLabel);
    return this.httpClient.post<APIResponse<CountDetailsModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/count`,
      filters,
      { params },
    );
  }

  postBlacklistCSVWithCampaignIds(
    organizationId: string,
    accountId: string,
    formData: FormData,
  ): Observable<APIResponse<DatasetModel>> {
    const params = new HttpParams()
      .append('mode', 'blacklist')
      .append('skip-first-line', true);

    return this.httpClient.post<APIResponse<DatasetModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/datasets/upload`,
      formData,
      { params },
    );
  }

  postImportProfile(
    organizationId: string,
    accountId: string,
    importObject: ImportObject,
  ): Observable<APIResponse<ImportModel>> {
    return this.httpClient.post<APIResponse<ImportModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/import-profiles`,
      importObject,
    );
  }

  getImportProfile(
    organizationId: string,
    accountId: string,
    profileImportId: string,
  ): Observable<APIResponse<ImportModel>> {
    return this.httpClient.get<APIResponse<ImportModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/import-profiles/${profileImportId}`,
    );
  }
}
