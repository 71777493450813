import { Injectable } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { profileActions } from './profile.actions';
import { CountDetailsModel } from '../models/count.model';
import { DatasetModel } from '../models/dataset.model';
import { ImportModel } from '../models/import.model';
import { ProfileService } from '../profile.service';

@Injectable()
export class ProfileEffects {
  getTargetReach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.getTargetReach),
      switchMap(
        ({ organizationId, accountId, filters, withLabel, dimensions }) =>
          this.profileService
            .getCount(organizationId, accountId, filters, withLabel, dimensions)
            .pipe(
              map((result: APIResponse<CountDetailsModel>) =>
                profileActions.targetReachRetrievedSuccessfully({
                  count: (result.data as CountDetailsModel).total,
                  counts: (result.data as CountDetailsModel).counts,
                }),
              ),
              catchError((error) =>
                of(profileActions.targetReachFailedToBeRetrieved({ error })),
              ),
            ),
      ),
    ),
  );

  uploadCountingDatasetCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.uploadBlacklistDatabaseWithCampaignIds),
      switchMap(({ organizationId, accountId, campaignIds, file }) => {
        const formData = new FormData();

        if (file) {
          formData.append('file', file);
        }

        if (campaignIds && campaignIds.length > 0) {
          formData.append('campaignIds', campaignIds.join(','));
        }

        return this.profileService
          .postBlacklistCSVWithCampaignIds(organizationId, accountId, formData)
          .pipe(
            map((result: APIResponse<DatasetModel>) =>
              profileActions.blacklistDatabaseWithCampaignIdsSentSuccessfully({
                datasetId: result?.data?.datasetId as string,
                datasetName: result?.data?.datasetName as string,
              }),
            ),
            catchError((error) =>
              of(
                profileActions.blacklistDatabaseWithCampaignIdsFailedToBeValidated(
                  { error },
                ),
              ),
            ),
          );
      }),
    ),
  );

  importProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.importProfile),
      switchMap(({ organizationId, accountId, importObject }) =>
        this.profileService
          .postImportProfile(organizationId, accountId, importObject)
          .pipe(
            map((result: APIResponse<ImportModel>) =>
              profileActions.importProfileSuccessfully({
                importProfileId: result?.data?.id as string,
              }),
            ),
            catchError((error) =>
              of(profileActions.importProfileFailedToBeUpload({ error })),
            ),
          ),
      ),
    ),
  );

  getImportProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.getImportProfile),
      switchMap(({ organizationId, accountId, profileImportId }) =>
        this.profileService
          .getImportProfile(organizationId, accountId, profileImportId)
          .pipe(
            map((result: APIResponse<ImportModel>) =>
              profileActions.importProfileRetrievedSuccessfully({
                name: (result.data as ImportModel).name,
                profileCount: (result.data as ImportModel).profileCount,
              }),
            ),
            catchError((error) =>
              of(profileActions.importProfileFailedToBeRetrieved({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
  ) {}
}
